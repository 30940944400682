<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            User Reviews
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-layout wrap justify-start v-if="user">
            <template v-for="(item, i) in user">
              <v-flex pa-6 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
                <v-layout wrap py-2>
                  <v-flex xs12 sm12 md12 lg6>
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
                        <v-img
                          :src="baseURL + item.user.image"
                          aspect-ratio="1"
                          contain
                          height="100px"
                        ></v-img>
                      </v-flex>
                      <v-flex xs12 sm9 md9 lg9 xl9>
                        <v-layout wrap justify-center fill-height>
                          <v-flex xs12>
                            <v-layout wrap justify-start>
                              <v-flex lg12 text-left>
                                <span style="font-size: 16px; color: #000000">
                                  {{ item.user.firstname }}
                                  {{ item.user.lastname }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 align-self-end>
                            <v-layout wrap justify-start>
                              <v-flex xs12 sm8 md8 lg8 xl8>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Email
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 16px">
                                      {{ item.user.email }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 sm4 md4 lg4 xl4>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Phone
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 16px">
                                      {{ item.user.phone }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex text-left xs1 text-center hidden-md-and-down>
                        <v-divider vertical></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex text-left xs12 md12 lg6>
                    <v-layout wrap justify-space-between fill-height>
                      <v-flex sm2 md2 lg2 xl2>
                        <v-layout wrap fill-height>
                          <v-flex xs6 sm12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Rating
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 text-left align-self-start>
                            <span style="font-size: 16px">
                              {{ item.rating }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex sm6 md6 lg6 xl6>
                        <v-layout wrap fill-height>
                          <v-flex xs6 sm12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Comment
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 text-left align-self-start>
                            <span style="font-size: 16px">
                              {{ item.comment }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex pt-5 xl4 xs4 sm4 md4 lg4 px-5 justify-end>
                        <v-dialog v-model="item.delete" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              tile
                              block
                              small
                              outlined
                              color="warning"
                              style="text-transform: none"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <span style="color: #000; font-size: 14px">
                                Delete
                              </span>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to delete this
                              Review?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.delete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItem(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <br />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 xl11 :key="i">
                <v-divider></v-divider>
              </v-flex>
            </template>
          </v-layout>
          <div class="pt-2" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      expanded: [],
      singleExpand: true,
      appLoading: false,
      dialogDelete: false,
      ServerError: false,
      showsnackbar:false,
      msg:null,
      search: "",
      currentPage: 1,
      pages: 0,
      totalData: 0,
      count: 10,
      totalRows: 0,
      user: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  methods: {
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/review/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/home/review/getlist",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    userinfo(uid) {
      this.$router.push({ name: "userReviewDetails", params: { userid: uid } });
    },
    edit(uid) {
      this.$router.push({ name: "edit", params: { userid: uid } });
    },
  },
};
</script>

<style>
</style>